// src/soundPlayer.js
import move from './sounds/move.ogg';
import newfriend from './sounds/newfriend.ogg';
import pushon from './sounds/pushon.ogg';
import pushoff from './sounds/pushoff.ogg';
import lostfriend from './sounds/lostfriend.ogg';

const soundEffects = {
	
  move: move,
  newfriend: newfriend,
  pushon: pushon,
  pushoff: pushoff,
  lostfriend: lostfriend
};

// Adjusted playSoundEffect to accept an optional volume parameter
export const playSoundEffect = (soundType, volume = 1.0) => {
  const soundFile = soundEffects[soundType];
  if (soundFile) {
    const audio = new Audio(soundFile);
    audio.volume = volume; // Set the volume (between 0.0 and 1.0)
    audio.play();
  } else {
    console.error(`Sound type "${soundType}" not found.`);
  }
};


// Function to alternate between two sounds with optional volume parameter
let toggle = true; // Toggle state to keep track of which sound to play

export const playAlternatingSoundEffect = (pushon, pushoff, volume = 1.0) => {
  if (toggle) {
    playSoundEffect(pushon, volume);
  } else {
    playSoundEffect(pushoff, volume);
  }
  toggle = !toggle; // Switch toggle state
};