import React from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Button,
} from '@mui/material';

const LeaderboardModal = ({ open, onClose, loading, leaderboardData }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Paper style={{ padding: '2rem' }}>
            <Typography variant="h4" align="center" gutterBottom>
              Leaderboard
            </Typography>
            {loading ? (
              <CircularProgress />
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Rank</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Wins</TableCell>
                      <TableCell>Losses</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leaderboardData.map((row, index) => (
                      <TableRow key={row.username}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.username}</TableCell>
                        <TableCell>{row.wins}</TableCell>
                        <TableCell>{row.losses}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Button onClick={onClose} variant="contained" color="primary" fullWidth style={{ marginTop: '1rem' }}>
              Close
            </Button>
          </Paper>
        </div>
      </Fade>
    </Modal>
  );
};

export default LeaderboardModal;
