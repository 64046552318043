/* src/HexagonalGrid.js */

import React, { Component } from 'react';
import { GridGenerator, HexGrid, Layout, Path, Text, Hexagon, HexUtils } from 'react-hexgrid';
import './App.css';
import ConfirmationModal from './ConfirmationModal'; // Import the ConfirmationModal component
import { findNextPlayerIndex, resetMovedUnits, checkSurroundedHexagons, pushCheck} from './GameLogic';
import io from 'socket.io-client'; // Import Socket.IO client
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { playSoundEffect, playAlternatingSoundEffect } from './SoundPlayer'; // Import the playSoundEffect
import { Button } from '@mui/material';
import Badge from '@mui/material/Badge';
import TokenIcon from '@mui/icons-material/Token'; // Replace with your desired token icon
import UndoIcon from '@mui/icons-material/Undo';



// Define the waitForPlayerToast function
function waitForPlayerToast(socket, players) {
  return new Promise((resolve) => {
    const toastId = toast.promise(
      // Create a promise that resolves after the 'startGame' event is received
      new Promise((resolve) => {
        // Listen for the 'startGame' event from the socket
        socket.once('startGame', (playerId) => {
          // Get the opposing player's name based on playerId
          const opposingPlayer = players.find(player => player.id != playerId.playerId && player.id != 0);
          const opposingPlayerName = opposingPlayer ? opposingPlayer.name : 'Opposing Player';
          
          // Display the success message with the opposing player's name
          toast.success(`${opposingPlayerName} has joined the game!`);
          
          // Resolve the promise when the event is received
          resolve();
        });
      }),
      // Configuration for the toast messages
      {
        pending: 'Waiting for another player to join...', // Use default pending message with the default loading icon
        //success: '', // Empty string, success message will be dynamically set
        error: 'Error: Toast manually closed',
      }
    );
  });
}



class App extends Component {
  constructor(props) {
    super(props);
	
	//this.socket = io('http://localhost:5000'); // Replace with your server URL not needed when using below
	this.socket = props.socket; // Initialize socket with the prop value from App.js
	this.playerId = props.playerId;
	this.isFullscreen = props.isFullscreen;
	this.isVolumeOn = props.isVolumeOn;

    // Define player representations
this.players = [
  { id: 0, name: "Player 0", color: "grey", shape: "circle", points: 0, score: 0},
  { id: 1, name: "Player 1", color: "blue", shape: "circle", points: 0, score: 0},
  { id: 2, name: "Player 2", color: "red", shape: "circle",  points: 0,  score: 0},
];
    
    // Create hexagons and assign them to players
    const hexagons = GridGenerator.hexagon(3).map(hex => ({
      ...hex,
      //owner: Math.random() < 0.5 ? 1 : 2, // Assign to player 1 or 2 - MODE 1 RANDOM
	  owner: hex.r === -3 ? 1 : hex.r === 3 ? 2 : 0, // Assign ownership based on row number - MAIN MODE
      //hasUnit: Math.random() < 0.2, // Adjust the probability as needed - MODE 1 RANDOM
	  hasUnit: hex.r === -3 ? true : hex.r === 3 ? true : false, // Assign ownership based on row number - MAIN MODE
      moved: false,
      attack: false,
	  pushed: null,
	  adjacent: null,
	  count: 0,
    }));
	
    this.state = {
      hexagons,
      path: { start: null, end: null },
      highlightedHexagons: [],
      currentPlayer: this.players[1], // Start with the first player
      selectedUnit: null,
      selectedUnitPosition: null,
      selectedHex: null,
	  clickedHex: null,
	  isModalOpen: false,
      isModalShownThisTurn: false,  // Track if the modal was shown this turn
	  displayMode: 'score', //default display mode
	  timestamp: Date.now(),// Initialize timestamp with the current time
	  turnStart: true,
	  isPushing: false, //throttles player interactions to protect animation, may not be needed?
	  moveHistory: [], // Initialize move history state
	  scoreHistory: [],
	  backButtonUses: 3,
	  moveCounter: 0
    };
	
  }


    // Listen for game state updates from the server with various methods
componentDidMount() {
	const { currentPlayer, turnStart } = this.state;
    const { socket, playerId} = this.props;
	 
    this.socket.on('startGame', ({ sessionId, playerId, usernames }) => {
      this.setState({ sessionId, playerId });
      
  // Set player names, default to "Guest" if the username is null or undefined
  this.players[1].name = usernames[0] || 'Guest'; // Assume this player is Player 1
  this.players[2].name = usernames[1] || 'Guest'; // Assume the other player is Player 2
		
    });
  
	
	
    socket.on('waitingForPlayer', () => {
		
      waitForPlayerToast(socket, this.players).then(() => {
		 
       
      });
    });
	
	//listens for state updates from server
	 this.socket.on('gameStateUpdate', ({ updatedState }) => {
		 
	 
	 
	 // only updates state of opponent. 
    if (this.props.playerId != this.state.currentPlayer.id) {
      this.setState({ ...updatedState });
	
	if(updatedState >= 0){
	this.players[this.state.currentPlayer.id].score = updatedState};
	  
	 
            console.log("Received from server", updatedState);
			
			
    }
	else{
            console.log("Ignoring update from server during this player's turn");
			
        }
		
	
	});
		
	//listen for state updates using a timestamp - works but not consistent 
 //   this.socket.on('gameStateUpdate', ({ timestamp, updatedState }) => {
//        if (timestamp > this.state.timestamp) {
//            this.setState({ ...updatedState, timestamp });
//            console.log("Received from server", updatedState);
 //       } else {
 //           console.log("Ignoring out-of-date update from server", this.state.timestamp, timestamp);
//        }
 //   });
 
}

componentWillUnmount() {
  const { socket } = this.props;

  // Remove the event listener when the component unmounts
  socket.off('waitingForPlayer', this.waitingForPlayerListener);
    // Remove the event listener when the component unmounts
  socket.off('gameStateUpdate', this.gameStateUpdateListener);
}


 //toast controller
notify = () => {
  const { currentPlayer, turnStart } = this.state;
  const { playerId } = this.props;
  const customId1 = "custom-id-yes1"; // to prevent multiple toasts
   const customId2 = "custom-id-yes2"; // to prevent multiple toasts
  


  if (playerId == null) {
    return;
  }

  const toastStyle = {
    backgroundColor: currentPlayer.color,
    color: 'white',
    opacity: 0.8, // Set the opacity to 0.8
  
  };


  if (turnStart && currentPlayer.id == playerId) {
	   
	  toast.dismiss(customId2);
	  
    toast(`${currentPlayer.name}'s Turn`, {
      toastId: customId1,
      style: toastStyle,
      // Other options like autoClose, onClose, etc. can be added here
    });
  } else if (turnStart && currentPlayer.id != playerId) {
	   toast.dismiss(customId1);
    toast(`${currentPlayer.name}'s Turn`, {
      toastId: customId2,
      style: toastStyle,
      // Other options like autoClose, onClose, etc. can be added here
    });
  }
  // Update turn state to prevent multiple toasts
  //this.setState({ turnStart: false });
};




onClick(event, source) {
    const { path, hexagons, selectedUnit, currentPlayer, selectedHex, isPushing, moveHistory, moveCounter} = this.state;
    const targetHex = source.state.hex;
	
    const { playerId } = this.props;

	// Prevent event propagation - appears to solve occasional pushing and movement bugs
    event.stopPropagation();
	
	
	 // Check if it's the current player's turn
    if (playerId != this.state.currentPlayer.id) {
      // If it's not the current player's turn, prevent interaction
      return;
    }

  if (this.state.isPushing) {
	//event.stopPropagation();  
   //return;
  }
	


    // Clear previous path or selection
    if (path.start == null) {
      path.start = source.state.hex;
    } else {
      path.start = null;
      path.end = null;
    }
		

    const clickedHex = hexagons.find(hex => HexUtils.equals(hex, targetHex));
    const isUnitHex = clickedHex && clickedHex.hasUnit;
	this.setState({ selectedHex: null, turnStart: false, timestamp: Date.now()}); //no longer need timestamp but okay
	 //this.socket.emit('updateGameState', { turnStart: false })

  
  
	 // Push the current state of hexagons to the history
	
if(isUnitHex & moveCounter >= moveHistory.length){
    this.setState(prevState => ({
      moveHistory: [...prevState.moveHistory, JSON.parse(JSON.stringify(prevState.hexagons))],
	  scoreHistory: [...prevState.scoreHistory, prevState.currentPlayer.score] // Store the current score
    }));
	}  
	
    // Movement Logic
    if (isUnitHex && clickedHex.owner === currentPlayer.id) {
      // If the clicked hexagon has a unit and the unit belongs to the current player
	  this.setState({ selectedHex: clickedHex });
	  this.socket.emit('updateGameState', { selectedHex: clickedHex })
      if (!clickedHex.moved) {
        this.setState({
          selectedUnit: clickedHex,
          selectedUnitPosition: targetHex,
        });
		

      } else {
        this.setState({
          selectedUnit: clickedHex,
          selectedUnitPosition: targetHex,
        });
      }
    } else if (selectedUnit && this.isHighlightedHex(targetHex) && !clickedHex.hasUnit ){
      // If a unit is selected and a highlighted hexagon is clicked, move the unit or attack
	  
	  
	  
      this.moveUnit(selectedUnit, targetHex);
	  this.setState({ selectedHex: clickedHex});  
	  this.socket.emit('updateGameState', { selectedHex: clickedHex })
	 
    } else if (this.isHighlightedHex(targetHex)) { 
      this.moveUnit(selectedHex, clickedHex);
    }

    // Highlight Controller
    if (!clickedHex.moved && clickedHex.owner === currentPlayer.id) {
      // Calculate highlighted hexagons that are exactly 2 hexagon distances away only if it's a unit hex
      const highlightedHexagons = isUnitHex
        ? hexagons.filter(hex => {
          const distance = HexUtils.distance(targetHex, hex);
          return distance <= 2 && !hex.hasUnit;
        })
        : [];
      this.setState({ path, highlightedHexagons });
	 this.socket.emit('updateGameState', {highlightedHexagons: highlightedHexagons});
    } else {
      this.setState({ path, highlightedHexagons: [] });
	 this.socket.emit('updateGameState', {highlightedHexagons: []});
    }

    if (clickedHex.moved && !clickedHex.attack && clickedHex.hasUnit && clickedHex.adjacent) {
      // Calculate highlighted hexagons that are exactly 1 hexagon distance away only if it's a unit hex
      this.highlightAttackableTiles(targetHex, currentPlayer, hexagons);
    }
	
// OnClick Adjacent Logic: Highlight tiles for movement and attack
if (isUnitHex && !clickedHex.moved && clickedHex.owner === currentPlayer.id) {
  const adjacentEnemyHexagons = hexagons.filter(hex => {
    const distance = HexUtils.distance(targetHex, hex);
    return distance === 1 && hex.hasUnit && hex.owner !== currentPlayer.id;
  });

  if (adjacentEnemyHexagons.length > 0) {
    const highlightedHexagons = hexagons.filter(hex => {
      const distance = HexUtils.distance(targetHex, hex);
      return distance === 1 && (hex.hasUnit && hex.owner) !== currentPlayer.id;
    });
	
	
	// Check if there is a unit behind the target hexagon that can be pushed
	const PushCheck = pushCheck(hexagons, highlightedHexagons, targetHex);

    this.setState({ highlightedHexagons : PushCheck });	
	this.socket.emit('updateGameState', {highlightedHexagons: PushCheck});
	
  } else {
    const highlightedHexagons = hexagons.filter(hex => {
      const distance = HexUtils.distance(targetHex, hex);
      return distance <= 2 && !hex.hasUnit;
    });

    this.setState({ path, highlightedHexagons });
	this.socket.emit('updateGameState', {highlightedHexagons: highlightedHexagons});
	
  }
  
}	

//this.socket.emit('updateGameState', {selectedHex: null}); //fixes the source push animation but we lost yellow multiplayer selection?



  } //on click ends


  
  // Highlights hexagons that can be attacked
  highlightAttackableTiles(targetHex, currentPlayer, hexagons) {
    const highlightedHexagons = hexagons.filter(hex => {
      const distance = HexUtils.distance(targetHex, hex);
      return distance === 1 && hex.hasUnit && hex.owner !== currentPlayer.id;
    });
	
	// Check if there is a unit behind the target hexagon that can be pushed
	const PushCheck = pushCheck(hexagons, highlightedHexagons, targetHex);

    this.setState({ highlightedHexagons : PushCheck });	
	this.socket.emit('updateGameState', {highlightedHexagons: PushCheck});
	 

  }
  

  // Checks if a hexagon is highlighted
  isHighlightedHex(hex) {
    const { highlightedHexagons } = this.state;
    return highlightedHexagons.some(highlightedHex => HexUtils.equals(hex, highlightedHex));
	
  }
  
  // Movement and Attack Mechanics
  moveUnit(unitHex, targetHex) {
    const { hexagons, currentPlayer, selectedUnit, selectedHex, moveCounter, moveHistory } = this.state;
	const { isVolumeOn } = this.props;


	//here??
	
					   // Push the current state of hexagons to the history

	
    // If the selected unit has not moved yet, move it
    if (!unitHex.moved && !targetHex.hasUnit ) {
	
      unitHex.hasUnit = false;
            if (isVolumeOn) {
        playSoundEffect('move');
      }
      // Update the target hexagon to have a unit and mark it as moved
      const targetIndex = hexagons.findIndex(hex => HexUtils.equals(hex, targetHex));
      if (targetIndex !== -1) {
        hexagons[targetIndex].hasUnit = true;
        hexagons[targetIndex].moved = true;
        hexagons[targetIndex].attack = false;
        hexagons[targetIndex].owner = currentPlayer.id; // Update the owner to the current player's id
      }
	  
	  
    }  else{ //Otherwise we can only push the unit
			
      this.pushUnit(unitHex, targetHex, hexagons, currentPlayer);

	}
	
    this.setState({
      selectedUnit: null,
      selectedUnitPosition: null,
      hexagons: [...hexagons],
      highlightedHexagons: [],
	  moveCounter: moveCounter + 1 // Increment move counter
    }); 

	const updatedHexagons = checkSurroundedHexagons(this.state.hexagons, currentPlayer, isVolumeOn);	
	this.socket.emit('updateGameState', this.state);
    this.socket.emit('updateGameState', {selectedHex: null}); //fixes source push animation in multiplayer
	
 
    this.setState(prevState => ({
    moveHistory: [...prevState.moveHistory, JSON.parse(JSON.stringify(prevState.hexagons))],
	  scoreHistory: [...prevState.scoreHistory, prevState.currentPlayer.score] // Store the current score
	
    }));
		
	   
  }
 
// Calculate the direction for nudge animation
calculateDirection(sourceHex, targetHex) {
  const qDiff = targetHex.q - sourceHex.q;
  const rDiff = targetHex.r - sourceHex.r;

  if (qDiff > 0 && rDiff === 0) {
    return 'East';
  } else if (qDiff < 0 && rDiff === 0) {
    return 'West';
  } else if (qDiff === 0 && rDiff > 0) {
    return 'SE';
  } else if (qDiff === 0 && rDiff < 0) {
    return 'NW';
  } else if (qDiff > 0 && rDiff < 0) {
    return 'NE';
  } else if (qDiff < 0 && rDiff > 0) {
    return 'SW';
  }

  // Handle other cases if needed

  return 'unknown';
}


 
// PUSH LOGIC
pushUnit(sourceHex, targetHex, hexagons, currentPlayer, isPushing) {
  const sourceIndex = hexagons.findIndex(hex => HexUtils.equals(hex, sourceHex));
  const targetIndex = hexagons.findIndex(hex => HexUtils.equals(hex, targetHex));
  

  const { isVolumeOn } = this.props;

    if (this.state.isPushing) {
    return;
  }
	
	
  
    this.setState({ isPushing: true });
	
	

  if (sourceIndex !== -1 && targetIndex !== -1) {
    // Check if the target hex is empty
    if (hexagons[targetIndex].hasUnit) {
      // Calculate the direction of the push
      const direction = {
        q: targetHex.q - sourceHex.q,
        r: targetHex.r - sourceHex.r,
        s: targetHex.s - sourceHex.s
      };

      // Calculate the new position for the target unit
      const newTargetHex = {
        q: targetHex.q + direction.q,
        r: targetHex.r + direction.r,
        s: targetHex.s + direction.s
      };

		const newTargetIndex = hexagons.findIndex(hex => HexUtils.equals(hex, newTargetHex));
	  
	      // Check if the target hex is outside the game board
		const newTargetOutsideBoard = newTargetHex.q < -3 || newTargetHex.q > 3 || newTargetHex.r < -3 || newTargetHex.r > 3 || newTargetHex.s < -3 || newTargetHex.s > 3;
  


      if (newTargetIndex !== -1 && !hexagons[newTargetIndex].hasUnit) {
        // Move the target unit to the new position
        hexagons[newTargetIndex].hasUnit = true;
        hexagons[newTargetIndex].moved = false;
        hexagons[newTargetIndex].attack = true; // or whatever the appropriate attack status is
        hexagons[newTargetIndex].owner = hexagons[targetIndex].owner;
		hexagons[newTargetIndex].count = hexagons[targetIndex].count + 1;
		currentPlayer.score = currentPlayer.score + hexagons[targetIndex].count + 1;
		
		
			this.players[currentPlayer.id].score = currentPlayer.score; //fixes multiplayer score?
			//this.players[currentPlayer.id].points = currentPlayer.points;
	
	//console.log(currentPlayer.score, currentPlayer, this.players);
		
		//console.log(currentPlayer.score, currentPlayer, this.players[currentPlayer.id]);
	    //this.players[currentPlayer.id] = currentPlayer;
	    this.socket.emit('updateGameState', currentPlayer.score); //fixes multiplayer scoring issue??
		

        // Clear the target hex
        hexagons[targetIndex].hasUnit = false;
        hexagons[targetIndex].moved = true;
        hexagons[targetIndex].attack = false;
		hexagons[targetIndex].count = null;

        // Update the owner to the current player's id
        hexagons[targetIndex].owner = 0;
		
		//Update source unit so they can no longer push or move if push was successful
		hexagons[sourceIndex].attack = true;
		hexagons[sourceIndex].moved = true;
	

    // Clear the highlighted hexagons and selectedHex
    this.setState({
      highlightedHexagons: [],
      selectedHex: null  // Clear the selectedHex when a push occurs
    });
	
	
	
	
  // Calculate the direction for nudge animation target
hexagons[newTargetIndex].pushed = this.calculateDirection(sourceHex, targetHex);

// Calculate the direction for the nudge animation source
hexagons[sourceIndex].pushed = 's' + this.calculateDirection(sourceHex, targetHex);

//FIX THE NUDGE for multiplayer here
if (isVolumeOn) {
 playAlternatingSoundEffect('pushon', 'pushoff');
  }
// Reset the pushed class after 500 milliseconds (adjust the duration as needed)
setTimeout(() => {
	hexagons[newTargetIndex].pushed = null;
	hexagons[sourceIndex].pushed = null; //nudge solution here?
	this.setState({ hexagons: [...hexagons] }); // Update the state to re-render with the updated hexagons
	this.setState({ isPushing: false });
	this.socket.emit('updateGameState', { hexagons: [...hexagons] });
	this.setState(prevState => {
      const moveHistory = [...prevState.moveHistory];
	  const scoreHistory = [...prevState.scoreHistory];
      moveHistory.pop(); // Remove the last entry
	   scoreHistory.pop();
      return { moveHistory, scoreHistory };
    });


}, 500); // Adjust the duration (in milliseconds) as needed
			
			
			
        // Clear the highlighted hexagons
        this.setState({
          selectedUnit: null,
          selectedUnitPosition: null,
          hexagons: [...hexagons],
          highlightedHexagons: [],
        });
		
		
		this.socket.emit('updateGameState', {selectedHex: null});
		
      } else if (newTargetOutsideBoard) {
		//alert('Cannot push. Target position is outside board.');
		
		//removal + animation here

		
		//Update source unit so they can no longer push or move if push was successful
		hexagons[sourceIndex].attack = true;
		hexagons[sourceIndex].moved = true;

  // Calculate the direction for nudge animation target
hexagons[targetIndex].pushed = 'f' + this.calculateDirection(sourceHex, targetHex);		
		// Calculate the direction for the nudge animation source
hexagons[sourceIndex].pushed = 's' + this.calculateDirection(sourceHex, targetHex);

//FIX THE NUDGE for multiplayer here
if (isVolumeOn) {
playSoundEffect('lostfriend', 0.1);	
}	
		// Reset the pushed class after 500 milliseconds (adjust the duration as needed)
setTimeout(() => {
	
	
	
		        // Clear the target hex
        hexagons[targetIndex].hasUnit = false;
        hexagons[targetIndex].moved = true;
        hexagons[targetIndex].attack = false;
	
	hexagons[targetIndex].pushed = null;
	hexagons[sourceIndex].pushed = null;
	
	if(!hexagons[targetIndex].count){
		hexagons[targetIndex].count = 2;
	} else hexagons[targetIndex].count = (hexagons[targetIndex].count)*2;
	

	currentPlayer.score = currentPlayer.score + hexagons[targetIndex].count;
	
	this.players[currentPlayer.id].score = currentPlayer.score;
	
	this.socket.emit('updateGameState', currentPlayer.score);
	
	//console.log(currentPlayer.score, currentPlayer, this.players);
	
	
	   // this.players[currentPlayer.id] = currentPlayer;
	    //this.socket.emit('updateGameState', currentPlayer);
		
		
	
	// Update the owner to the current player's id
        hexagons[targetIndex].owner = 0;
	const updatedHexagons = checkSurroundedHexagons(this.state.hexagons); // included here because it is not working under the move function when pushed off the edge
	
	this.setState({ hexagons: [...hexagons] }); // Update the state to re-render with the updated hexagons
	this.setState({ isPushing: false });
	this.socket.emit('updateGameState', { hexagons: [...hexagons] });
	this.setState(prevState => {
      const moveHistory = [...prevState.moveHistory];
	  const scoreHistory = [...prevState.scoreHistory];
      moveHistory.pop(); // Remove the last entry
	   scoreHistory.pop();
      return { moveHistory, scoreHistory };
    });
}, 1000); // Adjust the duration (in milliseconds) as needed
			
        // Clear the highlighted hexagons
        this.setState({
          selectedUnit: null,
          selectedUnitPosition: null,
          hexagons: [...hexagons],
          highlightedHexagons: [],
        });
		this.socket.emit('updateGameState', {selectedHex: null});
		

      } else {
		alert('Cannot push. Target position is occupied');  
	  }
    } 
  } 
}



  // Method to handle reversing the last move
  revertLastMove = () => {
	  
    this.setState(prevState => {
      const moveHistory = [...prevState.moveHistory];
	  const scoreHistory = [...prevState.scoreHistory];
	  const {moveCounter, selectedHex, clickedHex, currentPlayer} = this.state;
	  const { playerId } = this.props;


	
	
	 // Check if it's the current player's turn
    if (playerId != this.state.currentPlayer.id) {
      // If it's not the current player's turn, prevent interaction
      return;
    }
	
	
      if (moveHistory.length === 0) return null; // No moves to revert need to make it so the !currentPlayer cannot click
	  
	  if(selectedHex != null) {

			moveHistory.pop(); // Get the last state of hexagons
			scoreHistory.pop();
	  }
	  
	  if(moveCounter == 0){
		  	    this.setState({
	  moveCounter: moveCounter + 1 // Increment move counter
    }); 
	  }
	  
	  
  
      const previousState = moveHistory.pop(); // Get the last state of hexagons
	  const previousScore = scoreHistory.pop();
	  
	  
	 
	  console.log("reversing move", previousScore, currentPlayer.score);
	    // Add the global transition class
  document.body.classList.add('slow-transition');

  // After the transition duration, remove the class
  setTimeout(() => {
    document.body.classList.remove('slow-transition');
  }, 2000); // Match this duration with the CSS transition duration
	  
	  this.players[currentPlayer.id].score = previousScore; //fixes multiplayer score?
	  this.socket.emit('updateGameState', this.players[currentPlayer.id].score); //fixes multiplayer scoring issue??
	  
	  this.socket.emit('updateGameState', { hexagons: previousState,  selectedHex: null, highlightedHexagons: [], backButtonUses: prevState.backButtonUses - 1 });
      return { hexagons: previousState, moveHistory,  selectedHex: null, highlightedHexagons: [], moveCounter: moveCounter - 1, currentPlayer: {
        ...currentPlayer,
        score: previousScore // Revert to the previous score
      },
	  backButtonUses: prevState.backButtonUses - 1 // Decrement the remaining uses
	  };
    });
  };



   // Function to check if the current player has any valid moves or attacks
hasValidActions() {
    const { hexagons, players, currentPlayer, isPushing} = this.state;
	
	  const playerTileCounts = {};
	  const playerUnitCounts = {};

    if (this.state.isPushing) {
    return true;
  }

  // Calculate the tile count for each player
  hexagons.forEach(hex => {
    const owner = hex.owner;
    if (owner !== null) {
      if (!playerTileCounts[owner]) {
        playerTileCounts[owner] = 0;
		playerUnitCounts[owner] = 0;
      }
      playerTileCounts[owner]++;
	   if (hex.hasUnit == true){
		playerUnitCounts[owner]++;
    }

	}
  });
  
		const { playerId } = this.props;
  

  //WORK IN PROGRESS

 currentPlayer.points = playerTileCounts[currentPlayer.id] + currentPlayer.score;
 
 
 if(playerUnitCounts[1] <= 0 || playerUnitCounts[2] <= 0 ){
	 //currentPlayer.score = currentPlayer.score + 50; //if no units remain + win = +40 points
 }
	
	const hasVictory = currentPlayer.points >= 100 || playerUnitCounts[1] <= 0 || playerUnitCounts[2] <= 0;
	

    // Check if the currentPlayer has any valid moves or attacks
    const hasValidMoves = hexagons.some(hex => hex.owner === currentPlayer.id && hex.hasUnit && !hex.moved);

    // Check if the currentPlayer has any valid attacks
    const hasValidAttacks = hexagons.some(
      hex =>
        hex.owner === currentPlayer.id && hex.hasUnit &&
        !hex.attack && hex.adjacent        
    );
	   
if (hasVictory) {
  // Ensure that the emit happens only once by using a flag or some condition check
  
  if (!this.victoryEmitted) {
    this.victoryEmitted = true; // Set a flag to indicate the victory has been emitted

    // Emit the win to the server only if this player made the winning move
    if (playerId == this.state.currentPlayer.id) {	
  this.socket.emit('playerWin', {
    winnername: this.state.currentPlayer.name,
    losername: this.state.currentPlayer.name === this.players[1].name ? this.players[2].name : this.players[1].name
  
  });
	}
	}
		return false;
		}	
		
		    if (playerId != this.state.currentPlayer.id) {
      // If it's not the current player's turn, prevent interaction
      return true;
    }
	
return  hasVictory || hasValidMoves || hasValidAttacks;

  }
  


  handleConfirm = () => {
	  
    if (this.state.isPushing) {
    return;
  }
		  
	
    this.endTurn();
    this.setState({ isModalOpen: false});
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false, isModalShownThisTurn: true });
  };  
  
  
  
handleReset = () => {
	
window.location.reload();

  };

  
  
  // Ends the current player's turn
endTurn() {
  const { currentPlayer, hexagons } = this.state;

  const currentPlayerIndex = this.players.findIndex(player => player.id === currentPlayer.id);
  const nextPlayerIndex = findNextPlayerIndex(currentPlayerIndex, this.players);
  
      const { playerId } = this.props;
	
	 // Check if it's the current player's turn
    if (playerId != this.state.currentPlayer.id) {
      // If it's not the current player's turn, prevent interaction
      return;
    }


  this.setState({
    selectedUnit: null,
    selectedUnitPosition: null,
    highlightedHexagons: [],
    isModalShownThisTurn: false,
    currentPlayer: this.players[nextPlayerIndex],
	turnStart: true,
	moveHistory: [],
	moveCounter: 0,
	backButtonUses: 3,
  });


  
// Assuming hexagons is part of your component's state
const updatedHexagons = resetMovedUnits(this.state.hexagons);

// Set the state with the updated hexagons
this.setState({ hexagons: updatedHexagons });


//MULTIPLAYER



this.socket.emit('updateGameState', {
	hexagons: updatedHexagons,
	selectedUnit: null,
	selectedHex: null,
    selectedUnitPosition: null,
    highlightedHexagons: [],
    isModalShownThisTurn: false,
    currentPlayer: this.players[nextPlayerIndex],
	turnStart: true,
	moveHistory: [],
	moveCounter: 0,
	backButtonUses: 3,
});  




}

  

  
renderPlayerStats() {
  const { hexagons, currentPlayer, players, displayMode } = this.state;
  const playerTileCounts = {};

  // Calculate the tile count for each player
  hexagons.forEach(hex => {
    const owner = hex.owner;
    if (owner !== null) {
      if (!playerTileCounts[owner]) {
        playerTileCounts[owner] = 0;
      }
      playerTileCounts[owner]++;
    }
  });

 

  return (  // Render the tile count for each player within a HexGrid layout
  
    <HexGrid width={300} height={130} viewBox="-60 -55 120 120">
      <Layout size={{ x: 48, y: 48 }} flat={false} spacing={1.1} origin={{ x: 0, y: 0 }}>
        {this.players.map(player => {
          let qValue;
          if (player.id === 0) {
            qValue = 0; // Position player 0's hexagon in the center
          } else if (player.id === 1) {
            qValue = -1; // Position player 1's hexagon to the left
          } else {
            qValue = 1; // Position player 2's hexagon to the right
          }

          const isCurrentPlayer = player.id === currentPlayer.id;  // Check if this is the current player
		  
          return (
            <Hexagon
              key={player.id}
              q={qValue}
              r={0}
              s={-qValue}
              owner={player.id}
              className={`score ${isCurrentPlayer ? 'active' : ''}`}  // Apply 'active' class for the current player
			  opacity={isCurrentPlayer ? 1.0 : 0.0}  // Set opacity based on whether it's the current player
            >		
            </Hexagon>	
			
          );
        })}
		
      </Layout>
	  
	  //this is just so the score text doesn't pulse:
	  <Layout size={{ x: 44, y: 44 }} flat={false} spacing={1.2} origin={{ x: 0, y: 0 }}>
	  {this.players.map(player => {
          let qValue;
          if (player.id === 0) {
            qValue = 0; // Position player 0's hexagon in the center
          } else if (player.id === 1) {
            qValue = -1; // Position player 1's hexagon to the left
          } else {
            qValue = 1; // Position player 2's hexagon to the right
          }
		            // Check if this is the current player
          const isCurrentPlayer = player.id === currentPlayer.id;
		  
          return (
            <Hexagon
              key={player.id}
              q={qValue}
              r={0}
              s={-qValue}
              owner={player.id}
              className={"score"}  // Apply 'active' class for the current player
			  opacity={1}
			  onClick={() => this.toggleDisplayMode()}
            >
              <Text className={`score text ${player.id === 0 ? 'player-0-text' : ''}`} style={player.id === 0 ? { fontSize: '20px', fontWeight: 'bold' } : {}}>
              {/*  {displayMode === 'score' ? `${playerTileCounts[player.id] || 0}` : `${player.score}`} just displays scores */}
				
				{player.id === 0 ? (displayMode === 'score' ? 'SCORE' : 'COUNT' ) : (displayMode === 'score' ?  `${player.score + playerTileCounts[player.id] }` :`${playerTileCounts[player.id] || 0}`)}
						
					
              </Text>
			  
            </Hexagon>
			
          );
        })}
	  </Layout>
    </HexGrid>
	
		
	
  );
}

// Add this function to toggle the display mode
toggleDisplayMode() {
  this.setState(prevState => ({
    displayMode: prevState.displayMode === 'score' ? 'tileCount' : 'score',
  }));
 
}


  render() {
    const { isModalOpen, isModalShownThisTurn, hexagons, path, highlightedHexagons, selectedUnit, selectedUnitPosition, currentPlayer, selectedHex, turnStart } = this.state;
	
	// Check if the current player has any valid moves or attacks
    const hasValidActions = this.hasValidActions();
	const { playerId } = this.props;
	const { isFullscreen } = this.props;


	 // Dynamically set the button's background color based on the current player's color
  const buttonStyle = {
    backgroundColor: currentPlayer.color,
  };
  
	
    return (
      <div className="App" >
	 
	  <h2> {this.renderPlayerStats()} {/* Render player tile counts */}  </h2>
   
   {this.notify()}
	 
<ToastContainer
  position="top-right"
  autoClose={8000}
  hideProgressBar={false} // Set hideProgressBar to false to show the progress bar
  newestOnTop={false}
  closeOnClick={false}
  rtl={false}
  pauseOnFocusLoss={false} // Set to false to prevent pausing the progress bar on focus loss
  draggable
  pauseOnHover={false}
  progressStyle={{ backgroundColor: 'white', opacity: "1" }}
  closeButton={false}
  theme="colored"
  
/>

{/* Display Player 1's info above the game board */}
<div className="player-bar top">
  <svg height="40" width="40">
    <circle r="5" cx="10" cy="10" fill={this.players[1].color} />
	
{currentPlayer.id == 1 &&(
	 <circle r="5" cx="10" cy="10" fill={this.players[1].color} fillOpacity={1}  >;    
            <>
              <animate attributeName="r" from="5" to="10" dur="1.5s" begin="0s" repeatCount="indefinite" />
              <animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite" />
            </>


	  </circle>
	)}
  </svg>
  <span>{this.players[1].name || 'Guest'}</span>
</div>

{/* Display Player 2's info below the game board */}
<div className="player-bar bottom">
  <svg height="40" width="40">
    <circle r="5" cx="10" cy="10" fill={this.players[2].color} />

{currentPlayer.id == 2 &&(
 <circle r="5" cx="10" cy="10" fill={this.players[2].color} fillOpacity={1}  >;
          
            <>
              <animate attributeName="r" from="5" to="10" dur="1.5s" begin="0s" repeatCount="indefinite" />
              <animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite" />
            </>
 
	  </circle>
	  )}
  	</svg>			
 
  <span>{this.players[2].name || 'Guest'}</span>
</div>
	
	
        <HexGrid width={"95vw"} height={"90vh"} viewBox="-45 -45 90 90" >

          <Layout size={{ x: 6, y: 6 }} flat={false} spacing={1.1} origin={{ x: 0, y: 0 }}>
            {hexagons.map((hex, i) => (
              <Hexagon
                key={i}
                q={hex.q}
                r={hex.r}
                s={hex.s}
                owner={hex.owner} // Set the owner attribute based on the hexagon's owner
                className={(highlightedHexagons.some(highlightedHex => highlightedHex.q === hex.q && highlightedHex.r === hex.r && highlightedHex.s === hex.s)) ? 'active' : ((selectedHex && selectedHex.q === hex.q && selectedHex.r === hex.r && selectedHex.s === hex.s) ? 'selected' : '') + (hex.pushed ? hex.pushed : '')}  // Apply 'pushed' class if hexagon is pushed
                onClick={(e, h) => this.onClick(e, h)}
              >
			  
              {/*  <Text>{HexUtils.getID(hex)}</Text>  */}
			  {/*<Path start={path.start} end={selectedHex} /> //work on this to draw arrows*/}
			  
			  {/* ANIMATIONS HERE - still need to move CSS animations to function better */}
			  
                {hex.hasUnit && (
                  <ShapeRenderer
                    shape={this.players[hex.owner - 1].shape} // Use the player's shape based on the owner
					fillOpacity={hex.moved && hex.attack  ? '0.5' : '1.0'}						
                  />
				  
				  
                )}
				
				{!hex.attack && hex.hasUnit && (!hex.moved || hex.adjacent) && (
				 <ShapeAnimator
                    shape={this.players[hex.owner - 1].shape} // Use the player's shape based on the owner
					fillOpacity={hex.moved === true ? '0.5' : '1.0'}	
					hex={hex}
					currentPlayer={currentPlayer}
                  />
				)} 
					
					
				{hex.count && !hex.pushed && (hex.owner !== currentPlayer) && (
                  <PointRenderer
                    	hex={hex}
						currentPlayer={currentPlayer}
											
                  />
                )}	
		
				{hex.pushed == 'new'  && (
                  <PointRenderer
                    	hex={hex}
						currentPlayer={currentPlayer}
											
                  />
                )}	
		
			 {/* ANIMATIONS HERE - still need to move CSS animations to function better */}
			
			
             </Hexagon>
			
            ))}
			
			
            <Path start={path.start} end={path.end} />

          </Layout>
		  
        </HexGrid>
		

		 {/* THIS SHOWS THE SELECTED HEX AND SELECTED UNIT POSITIONS
		 
		
        {selectedUnit && (
          <p>Selected Unit: {HexUtils.getID(selectedUnitPosition)}</p>
        )}
        {selectedHex && (
          <p>Selected Hex: {HexUtils.getID(selectedHex)}</p>
        )}
		
				THIS SHOWS THE SELECTED HEX AND SELECTED UNIT POSITIONS */}
				
				
 {/* Conditionally show the ConfirmationModal */}
        { isModalShownThisTurn || hasValidActions ? true : (
          <ConfirmationModal
		    hexagons={hexagons}
		    players={this.players}
		    currentPlayer = {currentPlayer}
            isOpen={true} // Always open the modal when there are no valid actions
			onReset={() => this.handleReset()}
            onCancel={() => this.handleCancel()}
            onConfirm={() => this.handleConfirm()}
          />
        )}
   <h3 className={isFullscreen ? 'fullscreen-h3' : ''}><button style={buttonStyle} onClick={() => this.endTurn() }>End Turn?</button></h3>
   
           <Button
          variant="contained"
          color="secondary"
          onClick={this.revertLastMove} disabled={this.state.backButtonUses === 0 || playerId != currentPlayer.id}
          style={{ position: 'absolute', bottom: '10%', right: '5%' }}
		  sx={{ padding: '8px', minWidth: '12px',   }} // Adjust button padding here
        >
		{this.state.backButtonUses > 0 ? (
		<Badge  badgeContent={this.state.backButtonUses} color="primary">
          <UndoIcon />
		  </Badge>
		  ) : (
		  <Badge badgeContent={<TokenIcon sx={{ padding: '0px', fontSize: '0.75rem' }} />} color="primary">
		  <UndoIcon />
		  </Badge>
          )}  
        </Button>
   
     </div>
    );
  }
}


class PointRenderer extends React.Component {

  
  render() {
    const { hex, shape, fill, fillOpacity, className, currentPlayer } = this.props;
	
	const possibleSayings = ['Hello!', 'Nice to meet you!', ' Hi! ', 'Mommy!', 'Bonjour!'];
	  const randomIndex = Math.floor(Math.random() * possibleSayings.length);
	
	if(hex.pushed == 'new'){       return (      	
      <text
	  fill={fill}
         className="newfriend-animation"		
		 x="-0.0em"
		 y="-2em"  
		 
      >
	  {possibleSayings[randomIndex]}
      </text>
	  
	)}; 
	 
      return (      	
      <text
	  fill={fill}
        className="push-animation"	 
		 x="-0.5em"
		 y="-0.5em"   
      >
	  +{hex.count}
      </text>
	  
	  );  
    
    return null;
  }
}


class ShapeRenderer extends React.Component {
  render() {
    const { shape, fill, fillOpacity, className } = this.props;
    if (shape === "circle") {
      return ( <circle cx="0" cy="0" r="1" fill={fill} fillOpacity={fillOpacity}  className={className} >
	  </circle>
	  );  

    } 
    return null;
  }
}



class ShapeAnimator extends React.Component {
  shouldAnimate() {
    const { hex, currentPlayer } = this.props;
    return hex.owner === currentPlayer.id;
  }	
	
  render() {
    const { shape, fill, fillOpacity, className } = this.props;
    if (shape === "circle") {
      return ( <circle cx="0" cy="0" r="1" fill={fill} fillOpacity={fillOpacity}  className={className}>;
          {this.shouldAnimate() && (
            <>
              <animate attributeName="r" from="1" to="2" dur="1.5s" begin="0s" repeatCount="indefinite" />
              <animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite" />
            </>
          )}
	  </circle>
	  );
    } 
    return null;
  }
}


// Reset Animations on Click - needs some work - does this even do anything? I think we can remove it all.
document.addEventListener('click', function () {
	
  const animatedElements = document.querySelectorAll('.hexagon');
 
  // Add a class to pause animations
  animatedElements.forEach(function (element) {
    element.style.animation = 'none'; // Set animation to an empty string
  });

  // After 10 ms, remove the class to resume animations
  setTimeout(function () {
    animatedElements.forEach(function (element) {
      element.style.animation = ''; // Set animation to an empty string
    });
  }, 10);
});

export default App;

