import React from 'react';
import {
  Box,
  Modal,
  Backdrop,
  Fade,
  Typography,
  TextField,
  Button,
} from '@mui/material';

const ProfileModal = ({ open, onClose, newUsername, handleUsernameChange, handleUsernameSubmit }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            boxShadow: 24,
            p: 4,
            minWidth: 300,
            maxWidth: '80vw',
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <Typography variant="h6" align="center" sx={{ mb: 2 }}>
            Profile
          </Typography>
          <TextField
            label="Username"
            value={newUsername}
            onChange={handleUsernameChange}
            margin="normal"
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUsernameSubmit}
            sx={{ mt: 2 }}
            fullWidth
          >
            Update Username
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ProfileModal;
