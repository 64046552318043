import React, { useState, useEffect } from 'react';

const ConfirmationModal = ({ hexagons, players, currentPlayer, onReset, isOpen, onConfirm, onCancel }) => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        let timer;
        if (isOpen) {
            timer = setTimeout(() => setShowModal(true), 100); // 600 ms delay
        } else {
            setShowModal(false);
        }
        return () => clearTimeout(timer);
    }, [isOpen]);

    const modalStyle = {
        display: showModal ? 'block' : 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        zIndex: 1,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        padding: '0rem',
		opacity: .9,
    };

    const contentStyle = {
        backgroundColor: '#fefefe',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        border: '1px solid #888',
        maxWidth: '90%',
        width: '400px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
		
    };
	
	  const playerTileCounts = {};
	  const playerUnitCounts = {};

  // Calculate the tile count for each player
  hexagons.forEach(hex => {
    const owner = hex.owner;
    if (owner !== null) {
      if (!playerTileCounts[owner]) {
        playerTileCounts[owner] = 0;
		playerUnitCounts[owner] = 0;
      }
      playerTileCounts[owner]++;
	   if (hex.hasUnit == true){
		playerUnitCounts[owner]++;
    }

	}
  });
	
	//console.log(playerUnitCounts[1]);

    const hasVictory = currentPlayer.points >= 100 || playerUnitCounts[1] <= 0 || playerUnitCounts[2] <= 0;

    if (hasVictory) {
        return (
            <div style={modalStyle}>
                <div style={contentStyle}>
                    <p>{currentPlayer.name} Wins!!</p>
                    <button onClick={onReset}>Play Again</button>
						{/*<button onClick={onCancel}>Continue Playing</button>*/}
                </div>
            </div>
        );
    } else {
        return (
            <div style={modalStyle}>
                <div style={contentStyle}>
                    <p>End Turn?</p>
                    <button onClick={onConfirm}>Yes</button>
                    <button onClick={onCancel}>No</button>
                </div>
            </div>
        );
    }
};

export default ConfirmationModal;
