import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Avatar,
  Divider,
} from '@mui/material';
import { SignedIn, SignedOut, SignInButton, useUser, useClerk } from "@clerk/clerk-react";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ProfileModal from './ProfileModal';
import LeaderboardModal from './LeaderboardModal';
import HowToPlayModal from './HowToPlayModal';

function CustomUserMenu({ socket, username, setUsername }) {
  const { user } = useUser();
  const { signOut } = useClerk();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLeaderboard, setOpenLeaderboard] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [newUsername, setNewUsername] = useState(username);
  const [openHelp, setOpenHelp] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    setOpenProfile(true);
    handleCloseMenu();
  };

  const handleProfileClose = () => {
    setOpenProfile(false);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleLeaderboardClick = () => {
    setOpenLeaderboard(true);
    handleCloseMenu();
  };

  const handleLeaderboardClose = () => {
    setOpenLeaderboard(false);
  };

  const handleUsernameChange = (event) => {
    setNewUsername(event.target.value);
  };

  const handleUsernameSubmit = () => {
    if (!newUsername.trim()) {
      alert('Username cannot be blank');
      setNewUsername(username); // Reset to the old username
      return;
    }

    setUsername(newUsername);
    localStorage.setItem('username', newUsername);

    if (user.id) {
      socket.emit('updateUsername', { userId: user.id, username: newUsername });
    }

    setOpenProfile(false);
  };

  useEffect(() => {
    if (openLeaderboard) {
      setLoading(true);
      fetchLeaderboardData()
        .then((data) => {
          setLeaderboardData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching leaderboard data:', error);
          setLoading(false);
        });
    }

    if (openProfile) {
      setNewUsername(username);
    }
  }, [openLeaderboard, openProfile, username]);

  const fetchLeaderboardData = async () => {
    try {
      const response = await fetch('/leaderboard');
      if (!response.ok) {
        throw new Error('Failed to fetch leaderboard data');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
      throw error;
    }
  };

  const handleHelpClick = () => {
    setOpenHelp(true);
  };

  const handleHelpClose = () => {
    setOpenHelp(false);
  };

  const buttonStyles = {
    padding: '8px',
    bgcolor: 'white',
    color: 'black',
    '&:hover': {
      bgcolor: 'grey',
      opacity: 1,
    },
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '.4rem',
        left: '.4rem',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <SignedOut>
        <Box
          sx={{
            position: 'relative',
            top: '-.15rem',
            left: '.1rem',
          }}
        >
          <SignInButton
            style={{
              ...buttonStyles,
              padding: '8px',
              minWidth: '0',
              fontSize: '0.975rem',
              fontWeight: 'bold',
			  color: 'white',
              textTransform: 'uppercase',
            }}
          >
            Login
          </SignInButton>
        </Box>
      </SignedOut>

      <SignedIn>
        <IconButton onClick={handleOpenMenu}>
          {user ? (
            <>
              {user.imageUrl && (
                <Avatar
                  alt={user.fullName}
                  src={user.imageUrl}
                  sx={{ width: 26, height: 26, marginRight: 0 }}
                />
              )}
            </>
          ) : (
            <Typography variant="body1">User</Typography>
          )}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <Typography variant="h6" align="center" sx={{ p: 2 }}>
            Account Menu
          </Typography>
          <Divider />
          <MenuItem onClick={handleProfileClick}>
            <Typography variant="inherit">Username</Typography>
          </MenuItem>
          <MenuItem onClick={handleSignOut}>
            <Typography variant="inherit">Sign Out</Typography>
          </MenuItem>
        </Menu>
      </SignedIn>

      <IconButton
        onClick={handleHelpClick}
        sx={{
          ...buttonStyles,
          position: 'fixed',
          top: '.4rem',
          right: '3rem',
          padding: '6px',
        }}
      >
        <HelpOutlineIcon sx={{ fontSize: 26 }} />
      </IconButton>

      <IconButton
        onClick={handleLeaderboardClick}
        sx={{
          ...buttonStyles,
          position: 'fixed',
          top: '.4rem',
          right: '.4rem',
          padding: '6px',
        }}
      >
        <LeaderboardIcon sx={{ fontSize: 26 }} />
      </IconButton>

      {/* Profile Modal */}
      <ProfileModal
        open={openProfile}
        onClose={handleProfileClose}
        newUsername={newUsername}
        handleUsernameChange={handleUsernameChange}
        handleUsernameSubmit={handleUsernameSubmit}
      />
	  
      {/* Help Modal */}
      <HowToPlayModal
        open={openHelp}
        onClose={handleHelpClose}
      />

      {/* Leaderboard Modal */}
      <LeaderboardModal
        open={openLeaderboard}
        onClose={handleLeaderboardClose}
        loading={loading}
        leaderboardData={leaderboardData}
      />
    </Box>
  );
}

export default CustomUserMenu;
