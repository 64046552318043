// Footer.js
import React from 'react';
import { FaDiscord, FaPatreon, FaCoffee, FaReddit } from 'react-icons/fa';
import './footer.css'; // Create this file for custom styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="links">
          <a href="/about">About</a>
          
          <a href="/terms">Terms</a>
		  
		  <a href="/terms">Privacy</a>
			
        </div>
        <div className="legal">
          <p>© 2024 PUSH. All rights reserved.</p>
        </div>
        <div className="social-icons">
            <a href="https://www.reddit.com/r/playpush/" target="_blank" rel="noopener noreferrer" className="icon-link reddit" title="Reddit">
              <FaReddit />
            </a>
            <a href="https://discord.com/invite/CSReAStK5U" target="_blank" rel="noopener noreferrer" className="icon-link discord" title="Discord">
              <FaDiscord />
            </a>
            <a href="https://www.patreon.com/playpush" target="_blank" rel="noopener noreferrer" className="icon-link patreon" title="Patreon">
              <FaPatreon />
            </a>
            <a href="https://www.buymeacoffee.com/playpush" target="_blank" rel="noopener noreferrer" className="icon-link coffee" title="Buy Me a Coffee">
              <FaCoffee />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
