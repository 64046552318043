// ToastLibrary.js
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ToastLibrary {
  static show(message, options = {}) {
    // Default style for toast notification
    const defaultStyle = {
      backgroundColor: '#333', // Default background color
      color: '#fff', // Default text color
      opacity: 0.8, // Default opacity
    };

    // Merge custom styles with default style
    const style = { ...defaultStyle, ...options.style };

    // Display toast notification with the provided message and options
    toast(message, { ...options, style });
  }
}
export default ToastLibrary;