import React from 'react';
import {
  Modal,
  Box,
  Typography,
  Backdrop,
  Fade,
} from '@mui/material';

const HowToPlayModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            boxShadow: 24,
            p: 4,
            minWidth: 300,
            maxWidth: '80vw',
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <Typography variant="h6" align="center" sx={{ mb: 2 }}>
            How to Play
          </Typography>
          <Typography variant="body1" paragraph>
            {/* Add your instructions here */}
            1. Be the first to reach 100 points.
          </Typography>
          <Typography variant="body1" paragraph>
            2. All of your Dots can move once and/or PUSH once per turn. Dots may move then PUSH but cannot move after a PUSH. A pulsating dot can push or move:  IMAGE
          </Typography>
          <Typography variant="body1" paragraph>
            3. Move 2 spaces if no opponents are near: IMAGE
			   Move 1 space if an oppononent is near: IMAGE
          </Typography>
          <Typography variant="body1" paragraph>
            4. Dots may only be pushed if there is a free space behind from the direction of the push. It may be possible to push a dot off the board... 
          </Typography>
		  <Typography variant="body1" paragraph>
            5. Completely surrounding a tile that is not your color can create a new dot. 
          </Typography>
		  <Typography variant="body1" paragraph>
            6. Scoring: 1 point per space of your color. The 1st push = 1 point, 2nd push = 2 points, 3rd push = 3 points and so on. Push off the board and x2!
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
};

export default HowToPlayModal;
