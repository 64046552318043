// App.js

import React, { useEffect, useState } from 'react';
import './App.css';
import HexagonalGrid from './HexagonalGrid';
import io from 'socket.io-client';
import HamburgerMenu from './HamburgerMenu';
import { useAuth } from "@clerk/clerk-react";
import Footer from './footer';
import Logo from './Logo.png'; // Import the logo image
import RotateImage from './rotate.png';
import { IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [socket, setSocket] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [playerId, setPlayerId] = useState(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const { userId } = useAuth();
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [isLandscape, setIsLandscape] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isVolumeOn, setIsVolumeOn] = useState(true);

  useEffect(() => {
    const serverUrl = process.env.REACT_APP_SERVER_URL || ''; // Use environment variable for server URL
    const newSocket = io(serverUrl, {
      query: { userId }
    });

    setSocket(newSocket);

    // Event handlers
    const handleStartGame = ({ sessionId, playerId }) => {
      setSessionId(sessionId);
      setPlayerId(playerId);
      newSocket.emit('joinSession', sessionId);
    };

    const handleUsername = (receivedUsername) => {
		console.log(setUsername);
      if (receivedUsername && username !== receivedUsername) {
        setUsername(receivedUsername);
        localStorage.setItem('username', receivedUsername);
      }
    };

    const handleUsernameUpdateError = ({ message }) => {
      console.log('Username update error:', message);
      alert('Error updating username: ' + message);
    };

    newSocket.on('startGame', handleStartGame);
    newSocket.on('username', handleUsername);
    newSocket.on('usernameUpdateError', handleUsernameUpdateError);

    // Clean up
    return () => {
      newSocket.off('startGame', handleStartGame);
      newSocket.off('username', handleUsername);
      newSocket.off('usernameUpdateError', handleUsernameUpdateError);
      newSocket.disconnect();
      localStorage.removeItem('username');
    };
  }, [userId]);

  const handlePlayClick = () => {
    setIsPlaying(true);
    socket.emit('matchmake');
    setIsHeaderVisible(false);
  };



  const handleOrientationChange = () => {
    const isLandscapeMode = window.matchMedia("(orientation: landscape)").matches;
    const isMobileDevice = window.innerWidth <= 915; // Adjust as needed
    setIsLandscape(isLandscapeMode && isMobileDevice);
    setIsMobile(isMobileDevice);
  };

  useEffect(() => {
    handleOrientationChange(); // Check initial orientation
    window.addEventListener('resize', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);
  
    const handleFullscreenToggle = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        setIsFullscreen(true);
      }).catch((err) => {
        console.log(err);
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().then(() => {
          setIsFullscreen(false);
        }).catch((err) => {
          console.log(err);
        });
      }
    }
  };
  
    const handleVolumeToggle = () => {
    setIsVolumeOn(!isVolumeOn);
    if (isVolumeOn) {
//playSoundEffect(false); // Assuming playSoundEffect(false) turns off the sound
    } else {
   //   playSoundEffect(true); // Assuming playSoundEffect(true) turns on the sound
    }
  };

  return (
    <div className="App">
      {isLandscape && (
        <div className="landscape-warning visible">
          <div className="rotate-container">
            <img src={RotateImage} alt="Rotate Device" className="rotate-image" />
            <p className="rotate-text">Please rotate your device to portrait mode.</p>
          </div>
        </div>
      )}
      <header className={`App-header ${isHeaderVisible ? '' : 'hidden'}`}>
        <img src={Logo} alt="Logo" className="app-logo" />
      </header>
	  
	  
      <IconButton 
        className="fullscreen-button" 
        onClick={handleFullscreenToggle}
        sx={{
          padding: '6px',
          bgcolor: 'white',
          color: 'black',
          '&:hover': {
            bgcolor: 'grey',
          },
        }}
      
      >
        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </IconButton>
	  
	        <IconButton
        className="volume-button"
        onClick={handleVolumeToggle}
        sx={{
          padding: '6px',
          bgcolor: 'white',
          color: 'black',
          '&:hover': {
            bgcolor: 'grey',
          },
        }}
      >
        {isVolumeOn ? <VolumeUpIcon /> : <VolumeOffIcon />}
      </IconButton>
      

      <HamburgerMenu socket={socket} username={username} setUsername={setUsername} />

      <main className={`main-content ${isHeaderVisible ? '' : 'hidden-header'}`}>
        <section>
          {!isPlaying && (
            <>
              <p></p>
              <button onClick={handlePlayClick}>Play</button>
			{/*  <button onClick={handlePlayClick}>Invite</button>*/}
            </>
          )}
        </section>

        {isPlaying && <HexagonalGrid socket={socket} playerId={playerId} isFullscreen={isFullscreen}  isVolumeOn={isVolumeOn}  />}
      </main>

	  <Footer />
	 {/* <footer>
        <div className="player-info">
		<p>Player ID: {playerId}</p>
		<p>Session ID: {sessionId}</p>
          <p>© 2024 PUSH. All rights reserved.</p>
        </div> 
      </footer>*/}
    </div>
  );
}

export default App;
